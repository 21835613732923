@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body,
html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-header {
  font-size: 1.5rem;
  font-weight: 600;
  color: 1e293B;
}

.invoice-fitlers-lable {
  font-size: 14px;
  font-weight: 500;
  color: #334155;
}
