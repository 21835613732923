.App {
  height: 100%;
}
snap-global-header {
  position: relative;
  z-index: 10;
}

snap-global-header > .app-switcher-menu-container {
  z-index: 11;
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}
.cell-highlight {
  background-color: #efefef !important;
  height: 42px;
}
@media screen and (width <= 760px) {
  #hubspot-messages-iframe-container {
    bottom: 66px !important;
    min-width: 45px;
    min-height: 45px;
    width: 60px;
    height: 60px;
  }
  #hubspot-messages-iframe-container.widget-align-left {
    right: 0 !important;
    left: inherit !important;
  }
}
