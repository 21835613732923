@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./css/index.css";

@layer components {
  .card {
    @apply bg-white mt-4 p-4 border-2 border-gray-100 shadow-sm lg:border-gray-200 lg:mx-6 lg:rounded-lg lg:p-6 lg:pb-5;
  }
  .card-no-gutters {
    @apply bg-white mt-4 py-4 px-0 border-2 border-gray-100 shadow-sm lg:border-gray-200 lg:mx-6 lg:rounded-lg lg:py-6;
  }
  .modal-card {
    @apply bg-white py-4 px-4 lg:py-4 lg:px-6;
  }
  .wrapper {
    @apply bg-gray-100 pb-20 lg:pb-6;
  }
}

#input {
  position: absolute;
  border: none;
  font-size: 25px;
  text-align: center;
  background-color: transparent;
  outline: none;
}

snap-input input[type="date"] {
  text-transform: uppercase;
}

snap-main-navigation {
  z-index: 10 !important;
}

#mitekOuterContainer {
  z-index: 15 !important;
}
unit-elements-check-deposit {
  z-index: 16 !important;
}

@media screen and (max-width: 762px) {
  #launcher {
    bottom: 55px !important;
  }
  unit-elements-card > unit-elements-card-menu {
    z-index: 20 !important;
    position: relative;
  }
  unit-elements-account > unit-elements-account-menu {
    z-index: 20 !important;
    position: relative;
  }
}

#launcher {
  z-index: 8 !important;
}

#pieTabs > snap-tabs > .sc-snap-tabs > .snap-tabs > .snap-tabs-container {
  justify-content: center;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

#snap-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

#snap-left-path_to {
  animation: snap-left-path_move 2500ms linear infinite normal forwards;
}

@keyframes snap-left-path_move {
  0% {
    transform: translate(40.139293px, -14.4914px);
  }
  33.333333% {
    transform: translate(25.366798px, 14.491396px);
  }
  100% {
    transform: translate(25.366798px, 14.491396px);
  }
}

#snap-left-path {
  animation: snap-left-path_opacity 2500ms linear infinite normal forwards;
}

@keyframes snap-left-path_opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  83.333333% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

#snap-right-path_to {
  animation: snap-right-path_move 2500ms linear infinite normal forwards;
}

@keyframes snap-right-path_move {
  0% {
    transform: translate(9.289135px, 55.137952px);
  }

  33.333333% {
    transform: translate(28.000962px, 26.862052px);
  }

  100% {
    transform: translate(28.000962px, 26.862052px);
  }
}

#snap-right-path {
  animation: snap-right-path_opacity 2500ms linear infinite normal forwards;
}

@keyframes snap-right-path_opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  83.333333% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
