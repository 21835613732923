.payments-widget {
  background-color: white;
}
/* Text above widget when error occurs */
.error {
  text-align: center;
  font-weight: 600;
  color: rgb(185 28 28);
}

/* Select Previous Payment Method Button (Visa 1234) */
.payment-method-picker > ul > li > button {
  border-radius: 9999px !important;
  border: 1px solid #060706 !important;
  font-size: 14px !important;
  color: rgb(10, 9, 9) !important;
  padding: 9px 18px !important;
  text-transform: capitalize;
}

/* Use New Payment Method Button*/
.payment-method-picker > ul > li > div.toggle-new-pm > button {
  font-size: 14px !important;
  font-weight: bold;
  color: #2563eb !important;
  padding: 9px 0px 18px !important;
  text-transform: capitalize;
  margin-top: 7px;
  margin-bottom: 7px;
}

/*  Use Existing Payment Button  */
.new-payment-method > div > button {
  background-color: #ffffff !important;
  border-radius: 9999px !important;
  border: 2px solid #cbd5e1 !important;
  font-size: 14px !important;
  color: #1e293b !important;
  padding: 9px 18px !important;
  margin-bottom: 5px !important;
}
.new-payment-method > div > button:hover {
  background-color: #bfdbfe !important;
  border: 2px solid #cbd5e1 !important;
}

/* Confirm Payment Buttons Container */
.confirm-payment > :last-child {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

/* Confirm Payment Button */
.confirm-payment > div > :first-child {
  background-color: #2563eb !important;
  border-radius: 9999px !important;
  border: 2px solid #1e3a8a !important;
  font-size: 14px !important;
  color: #fff !important;
  padding: 9px 18px !important;
  text-transform: capitalize;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 100px;
}
.confirm-payment > div > :first-child:hover {
  background-color: #1e40af !important;
  border: 1px solid #1e3a8a !important;
}

/* Back (next to confirm payment) */
.confirm-payment > div > :last-child {
  background-color: #ffffff !important;
  border-radius: 9999px !important;
  border: 2px solid #cbd5e1 !important;
  font-size: 14px !important;
  color: #1e293b !important;
  padding: 9px 18px !important;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 7px;
  width: 100px;
}
.confirm-payment > div > :last-child:hover {
  background-color: #bfdbfe !important;
  border: 2px solid #cbd5e1 !important;
}

/* Submit button */
.new-payment-method > form > div > button {
  background-color: #2563eb !important;
  border-radius: 9999px !important;
  border: 2px solid #1e3a8a !important;
  font-size: 14px !important;
  color: white !important;
  padding: 6px 18px !important;
}
.new-payment-method > form > div > button:hover {
  background-color: #1e40af !important;
  border: 1px solid #1e3a8a !important;
}
